import { useRef, useCallback, useEffect, useMemo } from 'react';
export default function useDebouncedCallback(func, wait, options) {
    var _this = this;
    if (options === void 0) { options = { leading: false, trailing: true }; }
    var lastCallTime = useRef(undefined);
    var lastInvokeTime = useRef(0);
    var timerId = useRef(undefined);
    var lastArgs = useRef([]);
    var lastThis = useRef(null);
    var result = useRef(null);
    var funcRef = useRef(func);
    var mounted = useRef(true);
    funcRef.current = func;
    // Bypass `requestAnimationFrame` by explicitly setting `wait=0`.
    var useRAF = !wait && wait !== 0 && typeof window !== 'undefined' && typeof window.requestAnimationFrame === 'function';
    if (typeof func !== 'function') {
        throw new TypeError('Expected a function');
    }
    wait = Number(wait) || 0;
    var leading = !!options.leading;
    var trailing = 'trailing' in options ? !!options.trailing : true;
    var maxing = 'maxWait' in options;
    var maxWait = maxing ? Math.max(Number(options.maxWait) || 0, wait) : undefined;
    var invokeFunc = useCallback(function (time) {
        var args = lastArgs.current;
        var thisArg = lastThis.current;
        lastArgs.current = lastThis.current = undefined;
        lastInvokeTime.current = time;
        result.current = funcRef.current.apply(thisArg, args);
        return result.current;
    }, []);
    var startTimer = useCallback(function (pendingFunc, wait) {
        if (useRAF) {
            window.cancelAnimationFrame(timerId.current);
            return window.requestAnimationFrame(pendingFunc);
        }
        return setTimeout(pendingFunc, wait);
    }, [useRAF]);
    var cancelTimer = useCallback(function (id) {
        if (useRAF) {
            return window.cancelAnimationFrame(id);
        }
        clearTimeout(id);
    }, [useRAF]);
    var remainingWait = useCallback(function (time) {
        var timeSinceLastCall = time - lastCallTime.current;
        var timeSinceLastInvoke = time - lastInvokeTime.current;
        var timeWaiting = wait - timeSinceLastCall;
        return maxing ? Math.min(timeWaiting, maxWait - timeSinceLastInvoke) : timeWaiting;
    }, [maxWait, maxing, wait]);
    var shouldInvoke = useCallback(function (time) {
        if (!mounted.current)
            return false;
        var timeSinceLastCall = time - lastCallTime.current;
        var timeSinceLastInvoke = time - lastInvokeTime.current;
        // Either this is the first call, activity has stopped and we're at the
        // trailing edge, the system time has gone backwards and we're treating
        // it as the trailing edge, or we've hit the `maxWait` limit.
        return (lastCallTime.current === undefined ||
            timeSinceLastCall >= wait ||
            timeSinceLastCall < 0 ||
            (maxing && timeSinceLastInvoke >= maxWait));
    }, [maxWait, maxing, wait]);
    var trailingEdge = useCallback(function (time) {
        timerId.current = undefined;
        // Only invoke if we have `lastArgs` which means `func` has been
        // debounced at least once.
        if (trailing && lastArgs.current) {
            return invokeFunc(time);
        }
        lastArgs.current = lastThis.current = undefined;
        return result.current;
    }, [invokeFunc, trailing]);
    var timerExpired = useCallback(function () {
        var time = Date.now();
        if (shouldInvoke(time)) {
            return trailingEdge(time);
        }
        // Restart the timer.
        timerId.current = startTimer(timerExpired, remainingWait(time));
    }, [remainingWait, shouldInvoke, startTimer, trailingEdge]);
    var leadingEdge = useCallback(function (time) {
        // Reset any `maxWait` timer.
        lastInvokeTime.current = time;
        // Start the timer for the trailing edge.
        timerId.current = startTimer(timerExpired, wait);
        // Invoke the leading edge.
        return leading ? invokeFunc(time) : result.current;
    }, [invokeFunc, startTimer, leading, timerExpired, wait]);
    var cancel = useCallback(function () {
        if (timerId.current !== undefined) {
            cancelTimer(timerId.current);
        }
        lastInvokeTime.current = 0;
        lastArgs.current = lastCallTime.current = lastThis.current = timerId.current = undefined;
    }, [cancelTimer]);
    var flush = useCallback(function () {
        return timerId.current === undefined ? result.current : trailingEdge(Date.now());
    }, [trailingEdge]);
    useEffect(function () {
        mounted.current = true;
        return function () {
            mounted.current = false;
        };
    }, []);
    var debounced = useCallback(function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var time = Date.now();
        var isInvoking = shouldInvoke(time);
        lastArgs.current = args;
        lastThis.current = _this;
        lastCallTime.current = time;
        if (isInvoking) {
            if (timerId.current === undefined && mounted.current) {
                return leadingEdge(lastCallTime.current);
            }
            if (maxing) {
                // Handle invocations in a tight loop.
                timerId.current = startTimer(timerExpired, wait);
                return invokeFunc(lastCallTime.current);
            }
        }
        if (timerId.current === undefined) {
            timerId.current = startTimer(timerExpired, wait);
        }
        return result.current;
    }, [invokeFunc, leadingEdge, maxing, shouldInvoke, startTimer, timerExpired, wait]);
    var pending = useCallback(function () {
        return timerId.current !== undefined;
    }, []);
    var debouncedState = useMemo(function () { return ({
        callback: debounced,
        cancel: cancel,
        flush: flush,
        pending: pending,
    }); }, [debounced, cancel, flush, pending]);
    return debouncedState;
}
